import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Nav2 from "../Components/Nav2";

const skillsData = [
  { id: "html", icon: "fa-brands fa-html5", name: "HTML" },
  { id: "css", icon: "fa-brands fa-css3", name: "CSS" },
  { id: "js", icon: "fa-brands fa-square-js", name: "JavaScript" },
  { id: "react", icon: "fa-brands fa-react", name: "React.js" },
  { id: "node", icon: "fa-brands fa-node", name: "Node.js" },
  { id: "angular", icon: "fa-brands fa-angular", name: "Angular" },
  { id: "php", icon: "fa-brands fa-php", name: "PHP" },
  { id: "python", icon: "fa-brands fa-python", name: "Python" },
  { id: "wordpress", icon: "fa-brands fa-wordpress", name: "WordPress" },
];

const databasesData = [
  { id: "mongodb", icon: "fa-solid fa-database", name: "MongoDB" },
  { id: "mysql", icon: "ri-database-fill", name: "MySQL" },
  { id: "firebase", icon: "ri-fire-line", name: "Firebase" },
];

const toolsData = [
  { id: "vs-code", icon: "fa-solid fa-code", name: "VS Code" },
  { id: "studio", icon: "fa-brands fa-android", name: "Android Studio" },
  { id: "postman", icon: "fa-solid fa-network-wired", name: "Postman" },
  { id: "figma", icon: "fa-brands fa-figma", name: "Figma" },
  { id: "gimp", icon: "ri-image-edit-fill", name: "GIMP" },
  { id: "canva", icon: "fa-solid fa-pen-nib", name: "Canva" },
];

const ExperienceCard = ({ profile, place, date, location, number }) => (
  <div className="card">
    <div className="sub">
      <div className="font-medium s-font">{profile}</div>
      <div className="place">{place}</div>
      <div className="meta">
        <div className="date">{date}</div>
        <div className="location">{location}</div>
      </div>
    </div>
    <div className="num">{number}</div>
  </div>
);

const SkillCard = ({ id, icon, name }) => (
  <div className="card" id={id}>
    <div className="logo">
      <i className={icon}></i>
    </div>
    <div className="name">{name}</div>
  </div>
);

const About = () => {
  return (
    <>
      <Nav2 />
      <div className="page-intro">
        <div className="page-name">About</div>
        <div className="page-title">About me</div>
      </div>
      <div className="page-content">
        <div className="leading-8 text-justify flex gap-2 flex-col">
          <p>
            Hi there! My name is Tejaswi Anand and I am a passionate Developer
            specializing in the MERN stack. With a Bachelor's degree in Computer
            Science, I embarked on a journey to turn my love for coding into a
            career. As a Developer, I am continuously honing my skills and
            expanding my knowledge to create robust and user-friendly
            applications.
          </p>
          <p>
            I thrive in the fast-paced, ever-evolving nature of development,
            where I can combine my logical thinking with creativity to solve
            problems and build dynamic solutions. Whether it's crafting
            responsive front-end interfaces or designing efficient back-end
            systems, I strive for clean, scalable code that delivers an
            exceptional user experience.
          </p>
          <p>
            I find myself drawn to two fascinating realms: blockchain and AI. I
            strive to blend their potential to drive innovation and transform
            the way we live and interact.
          </p>
          <p>
            Beyond the realm of technology, music serves as a source of
            inspiration and relaxation in my life. Whether I'm exploring
            different genres or discovering emerging artists, music accompanies
            me throughout my coding endeavors, providing a soundtrack that fuels
            my creativity.
          </p>
          <p>
            In addition to my technical pursuits, I have a strong intellectual
            curiosity that extends beyond the digital world. Engaging with
            philosophy, economics, and geopolitics broadens my perspective on
            the world we inhabit. I enjoy immersing myself in philosophical
            inquiries, studying economic trends and theories, and analyzing
            geopolitical landscapes. These diverse subjects contribute to my
            well-rounded understanding of the world, complementing my technical
            skills with a broader context.
          </p>
          <p>
            I am driven by the desire to create innovative solutions that
            address real-world challenges, utilizing the power of technology and
            the insights gained from my multidisciplinary knowledge.
          </p>
          <p className="my-3 font-semibold s-font">
            Together, let's build the future of the web!
          </p>
        </div>
        <div className="box-wrap-1">
          <div className="exp">
            <div className="title">Work experience</div>
            <div className="cards">
              <ExperienceCard
                profile="Web Developer"
                place="Kanishka Software Private Limited"
                date="April 2023 - Present"
                location="Mumbai, IN - Remote"
                number="4"
              />
              <ExperienceCard
                profile="Web Developer Intern"
                place="Kanishka Software Private Limited"
                date="January 2023 - April 2023"
                location="Mumbai, IN - Remote"
                number="2"
              />
              <ExperienceCard
                profile="Technical Intern"
                place="Doubtfree"
                date="January 2022 - April 2022"
                location="Noida, IN - Remote"
                number="1"
              />
            </div>
          </div>
          <div className="exp">
            <div className="title">Education</div>
            <div className="cards">
              <ExperienceCard
                profile="B.Sc (Hons) Computer Science"
                place="Galgotias University"
                date="Aug 2020 - May 2023"
                location="Greater Noida, IN - Regular"
                number="2"
              />
              <ExperienceCard
                profile="Higher Secondary School (12th)"
                place="St Paul Senior Secondary School"
                date="April 2018 - March 2020"
                location="Begusarai, IN - Regular"
                number="1"
              />
            </div>
          </div>
        </div>
        <div className="about-skills">
          <div className="section-title">What I do</div>
          <div className="section-desc">
            From crafting visually appealing interfaces to developing robust
            back-end systems, I blend creativity with technical expertise.
            Explore what I do, a fusion of innovation and proficiency, shaping
            engaging and dynamic digital experiences.
          </div>
          <div className="skb-t1">Languages and Frameworks</div>
          <div className="skills-box">
            {skillsData.map((skill) => (
              <SkillCard key={skill.id} {...skill} />
            ))}
          </div>
          <div className="skb-t1">Databases</div>
          <div className="skills-box">
            {databasesData.map((db) => (
              <SkillCard key={db.id} {...db} />
            ))}
          </div>
          <div className="skb-t1">Tools</div>
          <div className="skills-box">
            {toolsData.map((tool) => (
              <SkillCard key={tool.id} {...tool} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
