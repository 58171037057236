// Navbar.js
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const handleScroll = () => {
        if (window.scrollY > 300) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }, 100);
  }, []);

  const navbarClasses =
    isFixed || window.scrollY > 300 ? "navbar fixed" : "navbar not-fixed";
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };
  const openResume = () => {
    const pdfUrl = "/data/tejaswianand_resume.pdf";
    window.open(pdfUrl, "_blank");
  };
  return (
    <>
      <div className={navbarClasses}>
        <div className="logo">T.Anand</div>
        <div className="menu text-white">
          <NavLink className="od" to="/">
            Home
          </NavLink>
          <NavLink className="od" to="/about">
            About
          </NavLink>
          <NavLink className="od" to="/work">
            Work
          </NavLink>
          <NavLink className="od" to="/connect">
            Connect
          </NavLink>
          <NavLink className="od resume" onClick={openResume}>
            Resume <i class="ri-file-pdf-2-fill"></i>
          </NavLink>
          <div className="hamburger om" onClick={toggleSidebar}>
            {isOpen ? (
              <i class="ri-close-line"></i>
            ) : (
              <i class="ri-menu-4-line"></i>
            )}
          </div>
        </div>
      </div>
      {isOpen && <div className="overlay" onClick={closeSidebar}></div>}
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-content">
          <div className="menu">
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About</NavLink>
              </li>
              <li>
                <NavLink to="/work">Work</NavLink>
              </li>
              <li>
                <NavLink to="/connect">Connect</NavLink>
              </li>
              <li>
                {" "}
                <NavLink className="resume" onClick={openResume}>
                  Resume <i class="ri-file-pdf-2-fill"></i>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
