import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Nav2 from "../Components/Nav2";

const PageNotFound = () => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      // Replace the URL with the one you want to redirect to
      window.location.replace("/");
    }, 10000);

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <>
      {" "}
      <Nav2 />
      <div className="page-not-found">
        <h1>404! - Page Not Found</h1>
        <p>Redirecting you to the homepage in {countdown} seconds...</p>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
