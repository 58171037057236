import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import PageNotFound from "./Pages/PageNotFound";
import About from "./Pages/About";
import Work from "./Pages/Work";
import Connect from "./Pages/Connect";
import ScrollToTop from "./Components/ScrollToTop";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="*" Component={PageNotFound}></Route>
        <Route path="/" Component={HomePage}></Route>
        <Route path="/about" Component={About}></Route>
        <Route path="/work" Component={Work}></Route>
        <Route path="/connect" Component={Connect}></Route>
      </Routes>
    </>
  );
};

export default App;
