import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="flex-sb">
        {" "}
        <div className="title">
          <span>Want to work together or just a Hii 👋🏼</span>Drop a mail at
          connect@tejaswianand.com
        </div>
        <div className="social">
          <NavLink to="https://in.linkedin.com/in/tejaswi27" target="_blank">
            <i class="fa-brands fa-linkedin"></i>
          </NavLink>

          <NavLink
            to="https://www.instagram.com/tejaswianand_027/"
            target="_blank"
          >
            <i class="fa-brands fa-instagram"></i>
          </NavLink>
        </div>
      </div>
      <div className="copyright">
        Made with{" "}
        <span>
          <i class="fa-solid fa-heart"></i>
        </span>{" "}
        by Tejaswi Anand
      </div>
    </div>
  );
};

export default Footer;
