import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SignatureLoader from "../Components/SignatureLoader";
import Footer from "../Components/Footer";
import HeaderImage from "../Assets/Images/header.jpg";
import ScrollToTop from "react-scroll-to-top";
import { projectsData } from "../Components/data";

const HomePage = () => {
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Simulating a 2-second delay before setting loading to false
    const timeout = setTimeout(() => {
      setLoading(false);
      setAnimate(true);
    }, 600);
    const timeout3 = setTimeout(() => {
      setAnimate(false);
    }, 700);

    // Clear the timeout on component unmount
    return () => clearTimeout(timeout, timeout3);
  }, []);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 700) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });

    return () => window.removeEventListener("scroll", () => {});
  }, []);

  const handleScrollButtonClick = () => {
    if (isScrolled) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 700,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {loading ? (
        <SignatureLoader />
      ) : (
        <div className={`page ${animate ? "fade-in" : "fade-in loaded"}`}>
          <Navbar />
          <div className="header" id="header">
            <div className="media">
              <img src={HeaderImage} alt="header-image" />
            </div>
            <div className="info">
              <div>
                <div className="headline">
                  Hi, I'm <span>Tejaswi Anand</span>
                </div>
                <div className="sub-headline">Full Stack Developer</div>
              </div>
              <div className="down-btn" onClick={handleScrollButtonClick}>
                <i class="ri-arrow-down-line"></i>
              </div>
            </div>
          </div>
          <div className="about" id="about">
            <div className="title">
              <h1>About</h1>
              <div className="section-name">About me</div>
            </div>{" "}
            <div className="info">
              Hi there! My name is Tejaswi Anand and I am a passionate Full
              Stack Developer specializing in the MERN stack. With a Bachelor's
              degree in Computer Science, I embarked on a journey to turn my
              love for coding into a career. As a Developer, I am continuously
              honing my skills and expanding my knowledge to create robust and
              user-friendly applications. I thrive in the fast-paced,
              ever-evolving nature ofdevelopment, where I can combine my logical
              thinking with creativity to solve problems and build dynamic
              solutions. Whether it's crafting responsive front-end interfaces
              with React.js or designing efficient back-end systems with Node.js
              and Express.js, I strive for clean, scalable code that delivers an
              exceptional user experience....
            </div>
            <button className="link" onClick={() => navigate("/about")}>
              Know More About me &rarr;
            </button>
          </div>
          <div className="skills" id="skills">
            <div className="title">
              <h1>Skills</h1>
              <div className="section-name">My Skills</div>
            </div>
            <div className="cards">
              <Carousel responsive={responsive}>
                <div className="card" id="html">
                  <div className="logo">
                    <i class="fa-brands fa-html5"></i>
                  </div>
                  <div className="name">HTML</div>
                </div>
                <div className="card" id="css">
                  <div className="logo">
                    <i class="fa-brands fa-css3"></i>
                  </div>
                  <div className="name">CSS</div>
                </div>

                <div className="card" id="js">
                  <div className="logo">
                    <i class="fa-brands fa-square-js"></i>{" "}
                  </div>
                  <div className="name">Javascript</div>
                </div>
                <div className="card" id="react">
                  <div className="logo">
                    <i class="fa-brands fa-react"></i>{" "}
                  </div>
                  <div className="name">React.js</div>
                </div>
                <div className="card" id="node">
                  <div className="logo">
                    <i class="fa-brands fa-node"></i>{" "}
                  </div>
                  <div className="name">Node.js</div>
                </div>
                <div className="card" id="php">
                  <div className="logo">
                    <i class="fa-brands fa-php"></i>{" "}
                  </div>
                  <div className="name">Php</div>
                </div>
                <div className="card" id="mongodb">
                  <div className="logo">
                    <i class="fa-solid fa-database"></i>{" "}
                  </div>
                  <div className="name">MongoDB</div>
                </div>

                <div className="card" id="mysql">
                  <div className="logo">
                    <i class="ri-database-fill"></i>
                  </div>
                  <div className="name">MySQL</div>
                </div>
                <div className="card" id="wordpress">
                  <div className="logo">
                    <i class="fa-brands fa-wordpress"></i>{" "}
                  </div>
                  <div className="name">wordpress</div>
                </div>
                <div className="card" id="cpanel">
                  <div className="logo">
                    <i class="fa-brands fa-cpanel"></i>{" "}
                  </div>
                  <div className="name">cPanel</div>
                </div>
                <div className="card" id="figma">
                  <div className="logo">
                    <i class="fa-brands fa-figma"></i>{" "}
                  </div>
                  <div className="name">Figma</div>
                </div>
                <div className="card" id="canva">
                  <div className="logo">
                    <i class="fa-solid fa-sliders"></i>{" "}
                  </div>
                  <div className="name">Canva</div>
                </div>
              </Carousel>
            </div>
          </div>
          <div className="work" id="work" name="work">
            <div className="title">
              <h1>Work</h1>
              <div className="section-name">My Projects</div>
            </div>
            <div className="cards">
              {projectsData?.map((data) => (
                <div className="card" key={data.id}>
                  <div className="text-lg md:text-xl lg:text-2xl">
                    <span className="font-bold">{data.name}</span>{" "}
                    <i class="ri-terminal-line"></i>
                    &nbsp;{data.info}
                  </div>
                  <div className="mt-[10px]">{data.description}</div>
                </div>
              ))}
            </div>
          </div>
          <Footer />
          <ScrollToTop smooth />
        </div>
      )}
    </>
  );
};

export default HomePage;
