export const projectsData = [
  {
    id: 1,
    name: "Simplhire",
    info: "Job Listing Website",
    description:
      "Developed a job listing platform using HTML, CSS, React.js, Node.js, MongoDB, and Tailwind CSS, focusing on user-friendly design and efficient job search functionality.",
  },
  {
    id: 2,
    name: "Trackye",
    info: "Expense Tracker App",
    description:
      "Built an expense tracker app with group expense tracking functionality using React Native and Node.js, enabling users to manage and share expenses effortlessly.",
  },
  {
    id: 3,
    name: "ChipherPad",
    info: "Encrypted Notes App",
    description:
      "Created an encrypted notes app using React Native, Node.js, and MongoDB, prioritizing data security and seamless user experience.",
  },
];
