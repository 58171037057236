// SignatureLoader.js
import React, { useEffect } from "react";
import Typed from "typed.js";

const SignatureLoader = () => {
  useEffect(() => {
    const options = {
      strings: ["T.Anand", "Loading..."],
      typeSpeed: 50,
      backSpeed: 25,
      showCursor: false,
      onComplete: () => {
        // Handle completion (e.g., navigate to the next page)
      },
    };

    const typed = new Typed("#signature-loader", options);

    // Clean up the Typed instance on component unmount
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div id="loader-container">
      <div id="signature-loader"></div>
    </div>
  );
};

export default SignatureLoader;
