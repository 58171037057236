import React, { useRef } from "react";
import Nav2 from "../Components/Nav2";
import Footer from "../Components/Footer";
import { NavLink } from "react-router-dom";
import emailjs from "emailjs-com";

const Connect = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_u2ln32y",
        "template_pcfydmd",
        form.current,
        "bATbevGeb-V86rkj8"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
          // Clear the form if needed
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <>
      <Nav2 />
      <div className="page-intro">
        <div className="page-name">Connect</div>
        <div className="page-title">Get in Touch</div>
      </div>
      <div className="page-content">
        <div className="connect">
          <div className="social">
            <div className="title">Social</div>
            <div>
              <NavLink
                to="https://in.linkedin.com/in/tejaswi27"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin"></i>
              </NavLink>
              <NavLink
                to="https://www.instagram.com/tejaswianand_027/"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </NavLink>
            </div>
          </div>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input type="text" name="user_name" required />
              <label>Email</label>
              <input type="email" name="user_email" required />
              <label>Phone</label>
              <input type="tel" name="user_phone" required />
              <label>Message</label>
              <textarea name="message" rows="5" required></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Connect;
