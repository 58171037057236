import React from "react";
import Nav2 from "../Components/Nav2";
import { NavLink } from "react-router-dom";
import Footer from "../Components/Footer";
import { projectsData } from "../Components/data";

const Work = () => {
  return (
    <>
      <Nav2 />
      <div className="page-intro">
        <div className="page-name">Work</div>
        <div className="page-title">my projects</div>
      </div>
      <div className="page-content">
        <div className="work-page">
          <div className="cards">
            {projectsData?.map((data) => (
              <div className="card" key={data.id}>
                <div className="text-lg md:text-xl lg:text-2xl">
                  <span className="font-bold">{data.name}</span>{" "}
                  <i class="ri-terminal-line"></i>
                  &nbsp;{data.info}
                </div>
                <div className="mt-[10px]">{data.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Work;
